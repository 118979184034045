import React, { Component } from 'react'
import { Grid, Col, Image } from 'react-bootstrap';
import './About.css';

export default class Careers extends Component {
  render() {
    return (
      <div>
        <Image src="assets/career1.jpg" className="header-image" />
        <Grid>
          <Col xs={12} sm={8} smOffset={2}>
           
            <h3>CAREERS</h3>
            <li>Team lead - With 4 to 5 years experience in e-commerce domain preferred IBM WCS.</li>
            <li>Java trainee - Kyber Services is currently recruiting Fresh Graduates (B.Tech/B.E any discipline, BCA or BSc computers) for developing web application using J2EE technologies.</li>
           
          </Col>
          <Col xs={12} sm={8} smOffset={2}>
           
            <h3>Desired Candidate Profile:</h3>
           <li>You will be trained on specific technologies as per Company's need in real time environment.</li>

           <li>On the completion of training, successful candidates will be absorbed to work on project.</li> 

           <li>Should have ability to learn new concepts and implement them quickly.</li>

           <li>Must be keen in upgrading &amp; improving to recent &amp; upcoming technologies.</li>

           <li>Should have strong interpersonal skills and be a good team player.</li>

           <li>Should have strong Analytically and Problem solving skills.</li>

          </Col>

          <Col xs={12} sm={8} smOffset={2}>
           
            <h3>Recruitment Process:</h3>
            <p>Should have good J2EE knowledge which includes Java, Servlets, JSP and at least one MVC framework. Should be comfortable with HTML and JavaScript. Should be familiar with at least one database. Online exam(s) followed by a face to face interview. Please send your resumes to careers@kyberservices.com.</p>
           
          </Col>
        </Grid>
      </div>
    )
  }
}
