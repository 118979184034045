import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Jumbotron, Grid, Row, Col, Image, Button } from 'react-bootstrap';

import './Home.css';

export default class Home extends Component {
  render() {
    return (
      <Grid>
        <Jumbotron >
          <h2 className="know" >Welcome to Kyber Services</h2>
          <p className="main">YOUR GATEWAY TO SUCCESS</p>
          <Link to="/about">
            <Button bsStyle="primary">Know More</Button>
          </Link>
        </Jumbotron>
        <Row className="show-grid text-center">
          
          <Col xs={12} sm={4} className="person-wrapper">
            <Image src="assets/j2ee1.png" circle className="profile-pic"/>
            <h3>J2EE Services</h3>
           <p align="justify" >Kyber Services J2EE practice is comprised of J2EE professionals with many years of experience in developing applications for medium as well as large scale enterprises. Our technology specialists are constantly involved in projects.which require development of applications that work and work well under rigid conditions with strict performance requirements.</p>
          </Col>
           <Col xs={12} sm={4} className="person-wrapper">
<div>
            <Image src="assets/oracle.jpg" circle className="profile-pic"/>
            <h3>ORACLE e-business suite </h3>
            <p align="left" ><font size="2">We Offer Both onshore and offshore model in techinical and techno-functional services.
 Services include:Solution Design,Development,Migraion,Conversion,Testing etc.</font></p>
<p align="justify" align="justify"><b>We support in the following modules :</b></p>

<p align="left" ><font size="2">Inventory Management,Order Management,Shipping Execution,Procurement,WMS,WIP,OSFM,e-Commerce Gateway, XML Gateway,ASCP and Demantra.


Technical Services - Forms, Reports, Interfaces, Conversions, WebADI, Dataloader, Testing etc.

We also offer our services in coordinating with Third Parties and Service Providers (like 3PL's, DI-Central, SPS Commerce, E2Open etc) for data transfers.</font></p>
</div>
          </Col>
          <Col xs={12} sm={4} className="person-wrapper">
            <Image src="assets/netsuite.png" circle className="profile-pic"/>
            <h3>NetSuite</h3>
           <p align="left">Kyber Services NetSuite development services extend and enhance the value of your software systems by designing, creating and developing unique dashboards, reports, settings and more that adapt to your business needs, using SuiteScript, SuiteAnalytics, SuiteFlows, SuiteBundle and SuiteBuilder developer tools.Our migration services ensure all important data is stored secure and maintains integrity.</p>

          </Col>
 </Row>
          <Row className="show-grid text-center">
           <Col xs={12} sm={4} className="person-wrapper">
            <Image src="assets/net1.jpg" circle className="profile-pic"/>
            <h3>.Net</h3>
            <p align="justify">Kyber Services is one of the leading Dot Net application development companies in India and the USA. We along with the bunch of our awesome development team aim to put forward the most reliable, secured, and scalable solutions. We offer a wide range of .Net based services and have proven capabilities to build robust and scalable web and enterprise-based web applications leveraging .Net to its best.</p>
          </Col>

         
          <Col xs={12} sm={4} className="person-wrapper">
            <Image src="assets/react.png" circle className="profile-pic"/>
            <h3>React</h3>
            <p align="left" >We Provide world-class ReactJS development services and we are globally renowned for offering best-fit technology solutions to our clients and focus on bringing up truly progressive web and mobile applications to meet the specific business objectives.We have a proficient team of React specialists with extensive experience in ReactJS development services.We design a creative mobile and web application UI/UX to fulfill the unique needs of our clients.</p>
          </Col>
          <Col xs={12} sm={4} className="person-wrapper">
            <Image src="assets/commerce.png" circle className="profile-pic"/>
            <h3>Commerce Solutions</h3>
            <p align="justify">Every merchant faces several challenges like changing commerce landscape with many channels to sell, the number of devices to support, social networks to integrate, staying on top of search engines and every day changing customer behavior. IBM Websphere commerce suite is a one-stop-shop with the new version storefronts based on current demands, robust architecture and powerful promotions and marketing tools.Team with over 10 years experience in IBM e-commerce product we offer.</p>
          </Col>
          <Col xs={12} sm={4} className="person-wrapper">
            <Image src="assets/appdevelopment.jpg" circle className="profile-pic"/>
            <h3>App Development</h3>
            <p align="justify">We provide business-oriented and feature-rich mobile apps that would perform well on Android and iOS platforms. As a prominent app development services rendering company we hold huge experience in crafting and developing an outstanding web app motorized with React/Redux. Our experts have resolved several challenges like multiple frameworks, SPA and many more using their skills in React & Angular.</p>
          </Col>
          
        </Row>


<Row >
 <h3>What We Do</h3>
  <p align="justify">Kyber Services is a technology company with a focus on hosting and the development of integrated e-commerce solutions tailored for small, medium, and large businesses. Since our inception, we have grown to provide a breadth of new services including web site development, promotional services, and high-end e-commerce hosting solutions. Our goal, to this day, is to provide a convenient, high-performance, and reliable web hosting experience. We've assembled a solid foundation of state-of-the-art servers, high-performance networks, and experienced professionals to ensure that Kyber Services remains a leader in the web hosting industry. We provide you with professional, reliable service and support which allows you to focus on the dynamic needs of your business. We know that, in the information age, reputation means everything, and that the only way to maintain success is by taking care of our customers and giving them every advantage that technology has to offer. We base our success on you - our customer. If your site's a success, we're a success!</p>
 </Row>

     
 <Row >
 <h3>Contact Information :</h3>
  <p align="justify"> Kyber Technology Services (P) Ltd. D.No.40-1-520, Behind BATA stores, K.K.Towers, Acharya Ranga Nagar, M.G.Road, Vijayawada-520010, India. Tel: +91 9032043089 salesus@kyberservices.com. </p>
 </Row>

      </Grid>
    )
  }
}
