import React, { Component } from 'react'
import { Grid, Col, Image } from 'react-bootstrap';
import './About.css';

export default class About extends Component {
  render() {
    return (
      <div>
        <Image src="assets/about3.jpg" className="header-image" />
        <Grid>
          <Col xs={12} sm={8} smOffset={2}>
           
            <h3>ABOUT US</h3>
            <p align="justify">We can help you decide the best hosting option based on your requirements, creating instances, deploying applications and maintaining your system. Our technology experts offer you full-spectrum Technology Consulting, Application Services, Systems Integration, Marketing Services, and E-Commerce Service</p>
           
          </Col>
          <Col xs={12} sm={8} smOffset={2}>
           
            <h3>What We Do</h3>
            <p align="justify">Kyber Services is a technology company with a focus on hosting and the development of integrated e-commerce solutions tailored for small, medium, and large businesses. Since our inception, we have grown to provide a breadth of new services including web site development, promotional services, and high-end e-commerce hosting solutions. Our goal, to this day, is to provide a convenient, high-performance, and reliable web hosting experience. We've assembled a solid foundation of state-of-the-art servers, high-performance networks, and experienced professionals to ensure that Kyber Services remains a leader in the web hosting industry. We provide you with professional, reliable service and support which allows you to focus on the dynamic needs of your business. We know that, in the information age, reputation means everything, and that the only way to maintain success is by taking care of our customers and giving them every advantage that technology has to offer. We base our success on you - our customer. If your site's a success, we're a success! </p>
           
          </Col>

          <Col xs={12} sm={8} smOffset={2}>
           
            <h3>Our Mission</h3>
            < p align="justify">Kyber Services is committed to provide highend and affordable e-commerce solutions.</p>
           
          </Col>
        </Grid>
      </div>
    )
  }
}
